import * as React from "react"
import { Link, HeadFC } from "gatsby"

const NotFoundPage = () => {
  return (
    <main>
      page not found.
    </main>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Page not found</title>
